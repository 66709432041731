<template>
    <section>
        <el-form :inline="true" size="mini" style="padding-left:10px;margin-bottom:-15px">
            <el-form-item label="部门名称:">
                <el-input v-model="departSearch" placeholder="请输入部门名称" size="mini" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="mini" plain @click="departFind">查询</el-button>
            </el-form-item>
        </el-form>
        
        <div id="departtree">
            <el-tree :load="loadDepartNode" :props="DepartProps" @node-click="DepartClick" lazy v-if="datatree" highlight-current :render-content="renderContent" :data="departsearchdata"></el-tree>
        </div>
        <!-- <el-button type="text" style="margin-top:-40px" @click="DepartChoose">{{text}}</el-button>
    <el-dialog title="选择部门" :visible.sync="addDepartVisible" v-model = "addDepartVisible" center append-to-body>
            <el-row>
                <el-col :span="7">
                    <el-input v-model="departSearch" placeholder="请输入部门名称" auto-complete="off" style="margin-top:7px"></el-input>
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" @click="departFind" size="mini" style="margin-top:11px">查询</el-button>
                </el-col>
            </el-row>
        <div id="departtree">
           <el-tree :load="loadDepartNode" :props="DepartProps" @node-click="DepartClick" lazy v-if="datatree" highlight-current :data="departsearchdata"></el-tree>
        </div>
    </el-dialog> -->
        <div slot="footer" style="text-align: center;">
            <el-button type="primary" size="mini" plain @click="addNewSubmit">提交</el-button>
        </div>
    </section>
</template>
<script>
import {GetChildrenList,GetDepartmentList,GetDepartmentTypes} from '../api/hr';
export default {
    name: "Departmenttool",
    data(){
        return{
            text: "选择所属部门",
            addDepartVisible: false,
            datatree: true,   //树重新加载
            DepartProps: {
            label: 'Name',
            isLeaf: 'Leaf'
            },
             position:[], // 选择的职务
            departSearch: null,
            departsearchdata: [],
            TypeIdModules: [],      //部门类型集合
        }
    },
    // props: [ "all", "single"], //接受父组件传值, //接受父组件传值
    methods: {
         //组织维护节点加载初始化
            loadDepartNode(node, resolve) {
                if(node.level === 0) {
                    this.loadfirstnode(resolve);              
                }
                if (node.level >= 1 && node.isLeaf == false) {
                    this.loadchildnode(node, resolve);
                }
            },
             //组织维护加载第一级节点
            loadfirstnode (resolve) {
                GetChildrenList().then((res) => {
                    const roledata = res.data.response;

                    resolve(roledata);
                });
            },
            //组织维护加载节点的子节点集合
            loadchildnode (node, resolve) {
                let para = {departmentId: node.data.Id};
                GetChildrenList(para).then((res) => {
                    const roledata = res.data.response;
                    resolve(roledata);
                });
            },
            //部门查询
            departFind(){      
                let para = {name: this.departSearch};
                    GetDepartmentList(para).then((res) => {
                        var myArray=new Array();
                        for(var i = 0; i<res.data.response.length; i++){
                            myArray.push(res.data.response[i]);
                        }     
                        this.departsearchdata=myArray;
                    });
            },
            //部门选择
            DepartChoose(){
                this.addDepartVisible = true;
                this.datatree = true;
                this.departSearch = null;
            },
            //职务树节点点击事件
            DepartClick(node){
                let departname = node;
                this.$emit("departcallFunction", node); //将值传给责任人
                this.position = node;
                // this.datatree = false;
            },
              renderContent (h, { node, data, store }) {
                var icon = "";
                for (var i = 0; i < this.TypeIdModules.length; i++) {
                    var tm = this.TypeIdModules[i];
                    if (tm.Id == data.TypeId) {
                    icon = tm.Icon;
                    break;
                    }
                }
                return (
                    <span class="custom-tree-node">
                    <i class={icon}>{node.label}</i>
                    </span>
                )
            }, 
              //提交选中新人员弹框
    addNewSubmit () {
  
    console.log(this.position);
      this.$emit("callback", this.position); //将值传给父组件
    }, 
    },
    mounted(){
         GetDepartmentTypes().then((res) => {
            this.TypeIdModules = res.data.response.data;
        });
        this.datatree = true;
    }
}
</script>
<style>
#departtree {
  overflow-y: auto;
  overflow-x: scroll;
  height: calc(100vh - 440px);
  /*width:200px;*/
  border: 0px solid blue;
}
.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #fabe64;
}

 .el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
}
.el-icon-caret-right:before {
  content: url("../assets/add.png") !important;
  font-size: 16px;
}
 .expanded:before {
  content: url("../assets/cancel.png") !important;
  font-size: 16px;
}
 .is-leaf.el-tree-node__expand-icon.el-icon-caret-right:before {
  content: "" !important;
  font-size: 16px;
}
</style>